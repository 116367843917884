<template>
    <header class="page-title">
        <h1><i class="bi bi-key-fill"></i> システム管理 - Webサイト管理</h1>
    </header>

    <!-- 検索欄 -->
    <section class="section">
        <inline-loader v-if="loading_count > 0"></inline-loader>
        <template v-else>
            <div class="mx-1 mb-3 px-3">
                <p class="my-0">該当 : {{ websites.length }} 件</p>
            </div>
            <table class="table">
                <thead>
                    <tr class="table-dark">
                        <th class="text-center col-6">サイト名</th>
                        <th class="text-center col-5">デフォルト事業部</th>
                        <th class="text-center col-5">デフォルトチャネル</th>
                        <th class="text-center col-5">デフォルト送信元ページ</th>
                        <th class="text-center col-5">デフォルトステータス</th>
                        <th class="text-center col-7">APIシークレットキー</th>
                        <th class="text-center col-3"></th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="websites.length">
                        <template v-for="website, index in websites" :key="website">
                            <tr>
                                <td class="align-middle">
                                    <form-input v-model="website.website_name"></form-input>
                                </td>
                                <td class="align-middle">
                                    <form-select-object
                                        v-model="website.default_department"
                                        :options="departments"
                                        option_value="department_id"
                                        option_label="department_name"
                                    ></form-select-object>
                                </td>
                                <td class="align-middle">
                                    <form-select-object
                                        v-model="website.default_channel"
                                        :options="channels"
                                        option_value="channel_id"
                                        option_label="channel_name"
                                    ></form-select-object>
                                </td>
                                <td class="align-middle">
                                    <form-select-object
                                        v-model="website.default_detailed_channel"
                                        :options="detailed_channels"
                                        option_value="detailed_channel_id"
                                        option_label="detailed_channel_name"
                                        empty_option="-- 未選択 --"
                                    ></form-select-object>
                                </td>
                                <td class="align-middle">
                                    <form-select
                                        v-model="website.default_status"
                                        :options="Status.options()"
                                    ></form-select>
                                </td>
                                <td class="align-middle">
                                    <div class="row align-items-center">
                                        <div class="col-30">
                                            {{ website.secret_key }}
                                        </div>
                                        <div class="col-6">
                                            <button
                                                type="button"
                                                class="btn btn-success"
                                                title="APIシークレットキー再生成"
                                                @click="regenerateReady(website, index)"
                                            >
                                                <i class="bi bi-arrow-repeat"></i>
                                            </button>
                                        </div>
                                    </div>
                                </td>
                                <td class="align-middle d-flex justify-content-between">
                                    <button @click="update(website)" class="btn btn-info">保存</button>
                                    <button @click="removeReady(website, index)" class="btn btn-outline-danger">削除</button>
                                </td>
                            </tr>
                        </template>
                    </template>
                </tbody>
                <tfoot>
                    <tr>
                        <td class="align-middle">
                            <form-input v-model="additional_website.website_name"></form-input>
                        </td>
                        <td class="align-middle">
                            <form-select-object
                                v-model="additional_website.default_department"
                                :options="departments"
                                option_value="department_id"
                                option_label="department_name"
                                empty_option="-- 選択 --"
                            ></form-select-object>
                        </td>
                        <td class="align-middle">
                            <form-select-object
                                v-model="additional_website.default_channel"
                                :options="channels"
                                option_value="channel_id"
                                option_label="channel_name"
                                empty_option="-- 選択 --"
                            ></form-select-object>
                        </td>
                        <td class="align-middle">
                            <form-select-object
                                v-model="additional_website.default_detailed_channel"
                                :options="detailed_channels"
                                option_value="detailed_channel_id"
                                option_label="detailed_channel_name"
                                empty_option="-- 選択 --"
                            ></form-select-object>
                        </td>
                        <td class="align-middle">
                            <form-select
                                v-model="additional_website.default_status"
                                :options="Status.options()"
                                empty_option="-- 選択 --"
                            ></form-select>
                        </td>
                        <td class="align-middle">
                            <button @click="create()" class="btn btn-info">登録</button>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </template>
    </section>

    <confirm-dialog ref="confirmRegenerate" @ok="regenerateSecretKey()">
        <p>APIシークレットキーを再生成してよろしいですか？</p>
    </confirm-dialog>

    <confirm-dialog ref="confirmRemove" @ok="remove()">
        <p>削除してよろしいですか？</p>
    </confirm-dialog>

</template>

<script>
import InlineLoader from '@/components/tools/InlineLoader.vue';
import FormInput from '@/components/forms/FormInput';
import FormSelect from '@/components/forms/FormSelect';
import FormSelectObject from '@/components/forms/FormSelectObject';
import Status from '@/models/enums/status';
import Website from '@/models/entities/website';
import Department from '@/models/entities/department';
import Channel from '@/models/entities/channel';
import DetailedChannel from '@/models/entities/detailed-channel';
import ConfirmDialog from '@/components/tools/ConfirmDialog.vue';

export default {
    name: 'PageSystemWebsite',
    components: {
        InlineLoader,
        FormInput,
        FormSelect,
        FormSelectObject,
        ConfirmDialog
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            // ローディング
            loading_count: 0,
            searching_count: 0,

            // 連携サイト一覧
            websites: [],

            // 追加用
            additional_website: new Website(),

            // API再生成 or 削除用
            target_website: new Website(),
            target_index: null,

            // option一覧
            departments: [], //事業部
            channels: [], //事業部
            detailed_channels: [], //事業部
            Status
        }
    },
    mounted() {
        this.fetchWebsites();
        this.fetchDepartments();
        this.fetchChannels();
        this.fetchDetailedChannels();
    },
    methods: {
        // 事業部取得
        fetchDepartments() {
            this.loading_count++;

            this.$http.get('/master/department')
            .then(response => {
                for (let row of response.data) {
                    this.departments.push(new Department(row));
                }
            })
            .finally(() => {
                this.loading_count--;
            });
        },
        // チャネル取得
        fetchChannels() {
            this.loading_count++;

            this.$http.get('/master/channel')
            .then(response => {
                for (let row of response.data) {
                    this.channels.push(new Channel(row));
                }
            })
            .finally(() => {
                this.loading_count--;
            });
        },
        // 送信元ページ取得
        fetchDetailedChannels() {
            this.loading_count++;

            this.$http.get('/master/detailed-channel/visible')
            .then(response => {
                for (let row of response.data) {
                    this.detailed_channels.push(new DetailedChannel(row));
                }
            })
            .finally(() => {
                this.loading_count--;
            });
        },
        // 連携サイト取得
        fetchWebsites() {
            this.loading_count++;

            this.$http.get('/system/website')
            .then(response => {
                for (let row of response.data) {
                    this.websites.push(new Website(row));
                }
            })
            .finally(() => {
                this.loading_count--;
            })
        },
        // 保存
        update(website) {
            this.startScreenLoading();

            this.$http.put(`/system/website/${website.website_id}`, website)
            .then(() => {
                this.showMessage('保存しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        // 登録
        create() {
            this.startScreenLoading();

            this.$http.post(`/system/website`, this.additional_website)
            .then((response) => {
                this.websites.push(new Website(response.data));
                this.additional_website = new Website();
                this.showMessage('登録しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        // APIシークレットキー再生成準備
        regenerateReady(website, index) {
            this.target_website = website;
            this.target_index = index;
            this.$refs.confirmRegenerate.show();
        },
        // APIシークレットキー再生成
        regenerateSecretKey() {
            this.startScreenLoading();

            this.$http.put(`/system/website/${this.target_website.website_id}/regenerate`)
            .then((response) => {
                this.websites.splice(this.target_index, 1, new Website(response.data));

                this.target_website = new Website();
                this.target_index = null;

                this.showMessage('APIシークレットトークンを再生成しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        // 削除準備
        removeReady(website, index) {
            this.target_website = website;
            this.target_index = index;
            this.$refs.confirmRemove.show();
        },
        // 削除
        remove() {
            this.startScreenLoading();

            this.$http.delete(`/system/website/${this.target_website.website_id}`)
            .then(() => {
                this.websites.splice(this.target_index, 1);

                this.target_website = new Website();
                this.target_index = null;

                this.showMessage('削除しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        }
    }
}
</script>

<style scoped>

</style>
